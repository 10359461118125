import { lazy, useEffect } from 'react';
import './App.css';
import AppRoutes from './components/Common/Content/Routes';
import { executeAuthorization } from './services/Auth.service';
// import Layout from './components/Common/Layout/Layout';
const Header = lazy (() => import ('./components/Common/Layout/Header'));
const Footer = lazy (() => import ('./components/Common/Layout/Footer'))

function App() {
  return (
    <>
      <Header />
      <AppRoutes />
      <Footer />
    </>
  )
}

export default App;

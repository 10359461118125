const APP_URL_BASE = "https://stg-gnssaugplanner.trimble.com/app";
const TID_URL_BASE = "https://stage.id.trimblecloud.com"
const SERVICE_URL_BASE = "https://stg-gnssaugplanner.trimble.com/tgap"

export const env = {
    clientId: "b941dc18-2704-4108-a834-7013d84782b3",
    applicationName: "Trimble-Planning-Tool",
    authCodeUrl: `${TID_URL_BASE}/oauth/authorize`,
    accessTokenUrl: `${TID_URL_BASE}/oauth/token`,
    logoutUrl: `${TID_URL_BASE}/oauth/logout`,
    redirectUrl: `${APP_URL_BASE}/auth/tid-redirect`,
    tenantDomain: "Trimble.com",
    applicationHomeUrl: `${APP_URL_BASE}`,
    authUrl: `${SERVICE_URL_BASE}/api/auth/init`,
    registerPath: "/app/sign-out",
    redirectPath: "/app/auth/tid-redirect"
}
import { PROJECT_CONSTANTS } from "../constants/Project.constants";
import { SHA256, enc } from 'crypto-js';
import { setItemInLocalStorage } from "./LocalStorage.utils";
var randomstring = require("randomstring");

export const generateCodeVerifier = () => {
    let codeVerifier = randomstring.generate({
        length: getLength(),
        charset: PROJECT_CONSTANTS.CODE_VERIFIER_CHARSET,
    })
    setItemInLocalStorage("codeVerifier", codeVerifier)
    return codeVerifier
}

export const getCodeChallenger = (codeVerifier: string) =>  {
    const hash = SHA256(codeVerifier);
    const codeChallenger = enc.Base64.stringify(hash)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
    return codeChallenger;
}

export const getCurrentTimeInSec = () => {
    return Math.floor(Date.now() / 1000);
}

const getLength = () => {
    return Math.floor(Math.random() * (128 - 43)) + 43;
}
import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { GetAuthorizationCode, SignOut } from '../../Auth/WithAuth';
const TrimblePlanningTool = lazy(() => import('../../../features/TptComponent'));

const ROUTES = () => (
  <>
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/app/auth/tid-redirect"  element={<GetAuthorizationCode />}/>
        <Route path="/sign-out"  element={<SignOut />}/>
        {/* <Route element={<WithAuth />}> */}
          <Route path="/app" element={<TrimblePlanningTool />} />
          <Route path="*" element={<Navigate to="/app" />} />
        {/* </Route>  */}
      </Routes>
    </Suspense>
  </Router>
  </>
);

export default ROUTES;
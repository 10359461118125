// Export respective env file based on process.ENV
import {env as devEnv} from './dev.config'
import {env as stgEnv} from './stg.config'
import {env as prodEnv} from './prod.config'

var tptEnv: any;
if (process.env.REACT_APP_ENV === 'development') {
    tptEnv = devEnv
} else if (process.env.REACT_APP_ENV === 'stage') {
    tptEnv = stgEnv
} else if (process.env.REACT_APP_ENV === 'production') {
    tptEnv = prodEnv
}
export default tptEnv;
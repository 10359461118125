import { generateCodeVerifier, getCodeChallenger, getCurrentTimeInSec } from "../utils/Auth.utils";
import { getItemFromLocalStorage, removeAllItemsFromLocalStorage, setItemInLocalStorage } from "../utils/LocalStorage.utils";
import { get } from "./Environment.service";

export const executeAuthorization = () => {
    window.location.href = getAuthCodeUrl()
}

const getAuthCodeUrl = () => {
    let authCodeUrl = get("authCodeUrl")
    authCodeUrl += "?client_id=" + get("clientId")
    authCodeUrl += "&response_type=code"
    authCodeUrl += "&scope=openid " + get("applicationName")
    authCodeUrl += "&redirect_uri=" + get("redirectUrl")
    authCodeUrl += "&code_challenge=" + getCodeChallenger(generateCodeVerifier()) 
    authCodeUrl += "&code_challenge_method=S256"
    return authCodeUrl;
}

export const getAccessToken = async () => {
    let authorization_code = getItemFromLocalStorage("authCode")

    let parameters = {
        "grant_type": "authorization_code",
        "code": authorization_code,
        "tenantDomain": get("tenantDomain"),
        "redirect_uri": get("redirectUrl"),
        "client_id": get("clientId"),
        "code_verifier": getItemFromLocalStorage("codeVerifier"),
        "code_challenge": getCodeChallenger(generateCodeVerifier()),
        "code_challenge_method": "S256"
    }
    const formBody = Object.entries(parameters).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&')
    const response = await fetch(get("accessTokenUrl"), {
        headers: new Headers(
            {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        ),
        method: 'POST',
        body: formBody
    })
    const responseData = await response.json();
    setItemInLocalStorage("refreshToken", responseData.refresh_token)
    setItemInLocalStorage("expiresIn", getCurrentTimeInSec()+ responseData.expires_in)
    let authBody: any = {
        idToken: responseData.id_token
    };
    try {
        const authResponse = await fetch(get("authUrl"), {
            headers: new Headers(
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${responseData.access_token}`
                }
            ),
            method: 'POST',
            body: JSON.stringify(authBody)
        })
        let authResponseData = await authResponse.json();
        setItemInLocalStorage("auth", JSON.stringify(authResponseData.body))
        if(!authResponse.ok) {
            throw new Error("Network response was not OK", authResponseData);
        }
    } catch (error) {
        console.log("Error occurred during Auth Init, ", error);
    }
    return responseData.access_token
}

export const logoutUser = () => {
    window.location.href = getLogoutUrl()
}

const getLogoutUrl = () => {
    let authCodeUrl = get("logoutUrl")
    authCodeUrl += "?id_token_hint =" + getItemFromLocalStorage("authCode")
    authCodeUrl += "&post_logout_redirect_uri=" + get("applicationHomeUrl")
    localStorage.clear();
    return authCodeUrl;
}

export const refreshToken = async () => {
    let parameters = {
        "grant_type": "refresh_token",
        "refresh_token": getItemFromLocalStorage("refreshToken"),
        "client_id": get("clientId"),
        "code_verifier": getItemFromLocalStorage("codeVerifier"),
        "code_challenge": getCodeChallenger(generateCodeVerifier()),
        "code_challenge_method": "S256"
    }
    const formBody = Object.entries(parameters).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&')
    const response: any = await fetch(get("accessTokenUrl"), {
        headers: new Headers(
            {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        ),
        method: 'POST',
        body: formBody,
    })
    if(response.status !== 200) {
        removeAllItemsFromLocalStorage()
        window.location.href = get("applicationHomeUrl")
    }
    const responseData = await response.json();
    setItemInLocalStorage("refreshToken", responseData.refresh_token)
    setItemInLocalStorage("accessToken", responseData.access_token)
    setItemInLocalStorage("expiresIn", getCurrentTimeInSec()+ responseData.expires_in)
}

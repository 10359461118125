import { useLocation } from "react-router-dom";
import { executeAuthorization, getAccessToken, logoutUser, refreshToken } from "../../services/Auth.service";
import { getItemFromLocalStorage, setItemInLocalStorage, removeAllItemsFromLocalStorage } from "../../utils/LocalStorage.utils";
import { get } from "../../services/Environment.service";
import { getCurrentTimeInSec } from "../../utils/Auth.utils";
import { useEffect } from "react";

const WithAuth = (WrappedComponent: any) => {
    const WithAuthentication = (props: any) => {
        const location = useLocation();
        const accessToken = getItemFromLocalStorage("accessToken")
        const authDetails = getItemFromLocalStorage("auth")
        const refreshToken = getItemFromLocalStorage("refreshToken")
        const expiresInTime = getItemFromLocalStorage("expiresIn")
        useEffect(() => {
            if (accessToken && accessToken !== "undefined" && authDetails && refreshToken && expiresInTime) {
                checkTokenValidity()
            }
        }, [location])
        if (!accessToken || accessToken == "undefined" || !authDetails) {
            return <HandleSession />
        } else {
            return <WrappedComponent {...props} />
        }
    }
    return WithAuthentication;
}

export default WithAuth;

const HandleSession = () => {
    const location = useLocation();
    useEffect(() => {
        (async () => {
            await executeAuthorization()
        })();
    }, [location])
    return null;
}     

export const GetAuthorizationCode = () => {
    let location = useLocation();
    useEffect(() => {
        (async () => {
            await handleAuthCode(location.search)
            let accessToken = await getAccessToken()
            if(accessToken) {
                setItemInLocalStorage("accessToken", accessToken)
                window.location.href = get("applicationHomeUrl")
            }
        })();
    }, [location])
    return null;
}

const checkTokenValidity = () => {
    const tokenExpiryTime: number | null = parseInt(getItemFromLocalStorage("expiresIn")!)
    if(tokenExpiryTime && (tokenExpiryTime - getCurrentTimeInSec() < 120)) {
        refreshToken();
    }
    return true;
}

const handleAuthCode = (locationString: string) => {
    (async () => {
        const codeRegex = /[?&]code=([^&]+)/;
        const match = codeRegex.exec(locationString);
        const authCodeValue = match && decodeURIComponent(match[1]);
        if(authCodeValue) {
            setItemInLocalStorage("authCode", authCodeValue)
        }
    })();
    return null;
}

export const SignOut = () => {
    (async () => {
        await logoutUser()
    })();
    return null;
}